.actions {
    margin-bottom: 18px;
    display: flex;
    align-items: flex-start;
    .button-box {
        display: inline-block;
        height: 40px;
        margin-left: 12px;
        padding: 0 20px;
        background-color: white;
        border: 1px solid white;
        border-radius: 5px;
        svg {
            margin-left: 10px;
        }
        &.preview{
            background-color: rgba(255,255,255,0.25);
            border: none;
        }
    }
    .more-button {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: stretch;
        height: 40px;
        margin-left: 12px;
        padding: 0 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font: 12px Shabnam;
        color: white;
        cursor: pointer;
        svg {
            margin-left: 10px;
            fill: white;
            vertical-align: middle;
        }
        span {
            vertical-align: middle;
            color: white;
            line-height: 40px;
        }
        &.has-icon span {
            display: inline-block;
            padding-top: 3px;
        }
        &:hover {
            span {
                color: #6eb8ff;
            }
            svg {
                fill: #6eb8ff;
            }
        }

    }
    .download-button {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: stretch;
        height: 40px;
        margin-left: 12px;
        padding: 0 20px;
        border: 1px solid white;
        border-radius: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font: 12px Shabnam;
        color: white;
        cursor: pointer;
        svg {
            margin-left: 10px;
            fill: white;
            vertical-align: middle;
        }
        span {
            vertical-align: middle;
            color: white;
            line-height: 40px;
        }
        &.has-icon span {
            display: inline-block;
        }
        &:hover {
            background-color: #1993ff;
            border-color: #1993ff;
        }

    }

    .button-title {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        font: 12px Shabnam;
        line-height: 40px;
        color: black;
    }
    .box-container {
        display: flex;
        align-items: center;
    }
    .date-box {
        color: #000000;
        display: inline-block;
        padding: 10px 15px;
        font-size: 14px;
        background: #ffc400;
        font-weight: bold;
        border-radius: 5px;
        margin-bottom: 10px;

        svg {
            margin-left: 10px;
        }
        .date-text {
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
            font: 14px Shabnam;
            color: black;
            font-weight: bold;
        }
    }
}