*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.Slider{
    min-height: 46.875vw;
    padding-bottom: 40px;
    position: relative;
    z-index: 0;
    .slider-arrowBox {
        position: absolute;
        left: 43px;
        bottom: 7.03125vw;
        display: flex;
        align-items: center;
        justify-content: center;
        .slider-arrow-left {
            transform: rotate(180deg);
            border-left: 1px solid rgba(255, 255, 255, 0.5);
        }
        .slider-arrow {
            display: flex;
            align-items: center;
            &:hover {
                .slider-arrow--chevron path:last-child {
                    opacity: 1;
                }
            }
        }
    }
    .slider-container{
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to left, rgba(18, 18, 18, 0), rgba(18, 18, 18, 0) 50%), linear-gradient(rgba(18, 18, 18, 0) 10vw, rgb(18, 18, 18) 46.875vw),
        url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOTIwIiBoZWlnaHQ9IjkwMCI+PHBhdGggZmlsbD0iIzM3MzgzZSIgZD0iTTAgMGgxOTIwdjkwMEgweiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+);
        position: absolute;
        z-index: -100;
        box-shadow: rgb(18, 18, 18) 0px -5px 5px inset;
        opacity: 0;
        direction: rtl;
        &.active {
            opacity: 1;
            z-index: 0;
            animation: 1s linear 0s 1 normal none running slideFadeIn;

        }
        &.previous {
            opacity: 0;
            animation: 1s linear 0s 1 normal none running slideFadeOut;

        }
        .slide-info-container {
            max-width: 50%;
            padding: 7.03125vw 43px;
            min-height: 37.5vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .logo-image {
                max-width: 20vw;
                max-height: 10vw;
                margin-bottom: 10px
            }
            .title {
                font-family: Shabnam;
                font-size: 20px;
                margin-bottom: 6px;
                color: white;
            }
        }
    }
}

@keyframes slideFadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
};
@keyframes slideFadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
};