.banner-item {
    padding: 0 8px;
    box-sizing: border-box;
    width: 414px;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    flex-direction: column;
    cursor: pointer;
    .item-title {
        height: 66px;
        font: 12px Shabnam;
        color: white;
    }
    .banner-image {
        height: 190px;
        margin-bottom: 8px;
        position: relative;
        background-color: #37383e;
        border-radius: 5px;
        overflow: hidden;
        img {
            position: absolute;
            border-radius: 5px;
            width: 100%;
            height: 100%;
        }

    }
}