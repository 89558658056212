.ads-item {
    padding: 0 8px;
    width: 483px;
    height: 230px;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    flex-direction: column;
    cursor: pointer;
    a {
        border-radius: 5px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
        }
    }
}