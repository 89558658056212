.movie-item {
    padding: 0 8px;
    width: 206px !important;
    margin-bottom: 32px;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    cursor: pointer;
    
    .item-title {
        height: 66px;
        font: 12px Shabnam;
        direction: rtl;
        color: white;
        .episode-title {
            color: #aaaaaa;
        }
    }
    position: absolute ;
    transform: translateY(0px);
    transition: transform .25s;
    top: 0;
    &:after {
        display: block;
        content: '';
        position: absolute;
        bottom: -15px;
        left: 25%;
        width: 50%;
        height: 5px;
        background: rgb(170,170,170);
        opacity: 0;
        border-radius: 4px;
        transition: opacity .25s;
    }
    &.active {
        transform: translateY(15px);
        &:after {
            opacity: 1
        }
    }

    .item-image {
        height: 280px;
        margin-bottom: 8px;
        position: relative;
        border-radius: 5px;
        background-color: #37383e;
        overflow: hidden;
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 5px;
        }
    }
    .item-hover-info {
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 5px;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all 0.5s;
        background-image: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.9));
        &:hover {
            opacity: 1;
        }
        .info-wrapper {
            position: absolute;
            width: 100%;
            bottom: 0;
            margin-bottom: 20px;
            .info-row {
                width: 100%;
                margin-bottom: 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding: 0 10px;
                font: 12px Shabnam;
                color: white;
                svg {
                    fill: white;
                    margin-left: 4px;
                    vertical-align: middle;
                }
                span {
                    vertical-align: middle;
                }
                &.has-icon span {
                    display: inline-block;
                    padding-top: 3px;
                }
            }
        }
    }

}