.episode-item {
    padding: 0 8px;
    width: 350px !important;
    margin-bottom: 32px;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    cursor: pointer;
    .episode-image {
        width: 100%;
        height: 225px;
        border-radius: 5px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
        }
        margin-bottom: 8px;
    }
    .episode-row {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-content: flex-start;
    }
    .episode-title h2 {
        margin-bottom: 4px;
        font-weight: normal;
        font: 15px Shabnam;
        color: white;
    }
    .episode-time {
        font: 12px Shabnam;
        color: #f2f2f2;
    }
    .episode-icon svg path {
        fill: white;
    }
    .episode-description {
        color: #aaaaaa;
        font: 13px Shabnam;
        margin-top: 10px;
    }
}