.range-slider {
    width: 65%;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .range-slider-rail {
        width: 100%;
        height: 2px;
        background-color: #37383e;

    }
    .slider-handle {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: white;
        position: absolute;
        transform: translate(-50%, -50%);
        z-index: 5;
    }
    .track {
        background-color: white;
        position: absolute;
        transform: translate(0, -50%);
        border-radius: 7px;
        height: 3px;
    }
    .date-slider {
        direction: ltr;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font: 12px Shabnam;
        color: white;
        margin-top: 20px;
    }
}
