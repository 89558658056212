.trailer-item {
    padding: 0 8px;
    width: 206px;
    margin-bottom: 24px;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    cursor: pointer;
    .trailer-image {
        display: block;
        height: 143px;
        position: relative;
        border-radius: 5px;
        background-color: #37383e;
        overflow: hidden;
        img {
            position: absolute;
            max-width: 100%;
            height: auto;
            border-radius: 5px;
        }
    }
}