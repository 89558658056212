.top-menu {
    width: 100%;
    height: 80px;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 20;
    background-color: rgba(18,18,18,0);
    transition: top .5s, box-shadow .5s, background-color 1s;
    background-image: linear-gradient(to bottom, rgba(18,18,18,1), rgba(18,18,18,0));
    display: flex;
    align-items: center;
    padding: 0 43px;
    box-sizing: border-box;
    direction: rtl;
    &.floating {
        position: fixed;
        top: -80px;
    }
    &.sticky {
        position: fixed;
        transition: top .5s;
        background-color: rgba(18,18,18,1);
        top: 0;
    }
    .logo {
        margin-left: 10px;
        svg {
            width: 57px;
            height: 40px;
            fill: white;
        }
    }
    nav.nav {
        margin-right: 20px;
        display: flex;
        align-items: center;
        .nav-item {
            font: 12px Shabnam;
            color: white;
            margin-left: 32px;
            &.active {
                color: #6eb8ff;
            }
        }
    }
    .search-button {
        color: white;
        margin-right: auto;
        svg {
            width: 40px;
            height: 40px;
            fill: white;
        }
    }
    .buy-plan {
        font: 12px Shabnam;
        color: white;
        margin-right: 20px;
    }
    .login-button {
        font: 12px Shabnam;
        color: white;
        margin-right: 20px;
        padding: 0 10px;
        border-radius: 5px;
        border: 1px solid white;
        line-height: 35px;
    }
}