.movie-detail {
    width: 100%;
    height: 100%;
    direction: ltr;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    position: relative;
    background-color: #1A1A1A;
    .loading-svg {
        stroke: white;
        animation-name: fadeInPulseSVGLOADING;
        animation-duration: 2s;
    }
    .movie-image {
        background-color: #1A1A1A;
        direction: ltr;
        font-size: 0;
        height: 100%;
        &:after {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            left: 10%;
            right: 25%;
            bottom: 0;
            background: linear-gradient(to left, #1A1A1A , rgba(26,26,26,0));
        }
        img {
            width: 75%;
            max-width: 100%;
            border: 0;
            height: 100%;
        }
    }
    .logo-image {
        max-width: 20vw;
        max-height: 10vw;
        margin-bottom: 18px
    }
    .movie-detail-box {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        direction: rtl;
        max-width: 700px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        padding: 0 43px;
        .title {
            display: flex;
            align-items: center;
            font: 20px Shabnam;
            color: white;
            margin-bottom: 6px;
            a {
                color: white;
            }
            svg {
                fill: white;
                width: 20px;
                height: 30px;
                margin-right: 8px;
                opacity: 0;
                transition: opacity 0.4s;
            }
            &:hover svg {
                opacity: 1;
            }
        }
        .detail-row {
            width: 100%;
            display: flex;
            align-items: center;
        }
        .text-row {
            margin-bottom: 6px;
            font: 12px Shabnam;
            color: #cccccc;
            span {
                padding-left:8px;
            }
            span.separator {
                padding: 0 8px;
            }
            a {
                color: inherit;
            }
        }
        .age-limitation {
            margin-left: 17px;
            svg {
                width: 30px;
                height: 20px;
            }
            margin-bottom: 10px;
        }
        .detail-item {
            padding-left: 20px;
            font: 12px Shabnam;
            margin-bottom: 10px;
            color: white;
            white-space: nowrap;
            font: 12px Shabnam;
            color: white;
            &.has-icon {
                svg {
                    fill: white;
                    margin-left: 4px;
                    vertical-align: middle;
                }
                span {
                    vertical-align: middle;
                }
                &.has-icon span {
                    display: inline-block;
                    padding-top: 3px;
                }
            }
        }
        .detail-description {
            margin-bottom: 18px;
            font: 12px Shabnam;
            color: white;
        }

    }
    &.top-media {
        .detail-description {
            font-size: 14px;
        }
        .title {
            font-size: 21px;
            font-weight: bold;
        }
        .movie-detail-box {
            line-height: 1.75;
        }

    }
}


@keyframes movieDetailFadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
};
@keyframes movieDetailFadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
};

@keyframes fadeInPulseSVGLOADING {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}