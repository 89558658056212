.options-container {
    height: 100%;
    padding: 27px 24px 27px 0;
    display: flex;
    align-items: start;
    flex-wrap: wrap;
}
.range-slider {
    align-self: stretch;
}
.options {
    width: 15%;
    display: flex;
    flex-direction: column;
}
.option-item {
    display: flex;
    align-items: center;
    font: 12px Shabnam;
    color: white;
    margin-bottom: 20px;
    .option-item-icon-container {
        width: 15px;
        height: 15px;
        margin-left: 5px;
    }
}