.collection-detail {
    width: 100%;
    height: 100%;
    direction: ltr;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    position: relative;
    background-color: #1A1A1A;
    .loading-svg {
        stroke: white;
        animation-name: fadeInPulseSVGLOADING;
        animation-duration: 2s;
    }

    .collection-detail-box {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        direction: rtl;
        display: flex;
        max-width: 760px;
        flex-direction: column;
        justify-content: flex-end;
        align-items: stretch;
        padding: 0 43px;
        .title {
            display: flex;
            font: 30px Shabnam;
            color: white;
            margin-bottom: 8px;
        }
        .detail-row {
            width: 100%;
            display: flex;
            align-items: center;
        }
        .detail-description {
            margin-bottom: 52px;
            font: 14px Shabnam;
            color: white;

        }

    }
}


@keyframes movieDetailFadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
};
@keyframes movieDetailFadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
};

@keyframes fadeInPulseSVGLOADING {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}