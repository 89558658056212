@import url('https://v1.fontapi.ir/css/Shabnam');
*{
    font-family: shabnam;
}
a{
    text-decoration: none;
}

body{
    background-color: #121212;
}