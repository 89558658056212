.preview-item {
    width: 100%;
    height: 0;
    overflow: hidden;
    margin-top: 15px;
    margin-bottom: 48px;
    transition: height .5s, margin .5s;
    position: relative;
    direction: ltr;
    user-select: text;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    &.active {
        height: 35.15625vw;
    }
}