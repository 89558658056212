.multi-list {
    direction: rtl;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .multi-title {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        margin-bottom: 16px;
        padding: 0 43px;
        align-items: center;
        h3 {
            font: 18px Shabnam;
            color: white;
        }
        a {
            font: 12px Shabnam;
            color: white;
            display: flex;
            align-items: center;
            margin-right: 16px;
            text-decoration: none;
            opacity: 0;
            transition: opacity 0.4s;
            &:hover {
                opacity: 1;
            }
            svg {
                fill: white;
                width: 20px;
                height: 30px;
                margin-right: 5px;
            }
        }
    }
    & > .list-container {
        width: 100%;
        overflow: hidden;
        padding: 0 43px 5px 43px;
        & > .row {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            .flickity-viewport {
                overflow: inherit;
            }
        }
    }
}

.Advertisement .list-container {
    margin-bottom: 65px;
}

.multi-list .single-row .movie-item{
    position: relative;
}